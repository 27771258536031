body {
  overflow-y: hidden;

  .actions {
    button {
      margin-right: .33rem;
    }
  }
}

body {
  * {
    scrollbar-color: pink lime;
  }
}

.MuiContainer-maxWidthXl {
  max-width: unset !important;
}

html {
  *::-webkit-scrollbar-track
  {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: inherit;
  }
  
  *::-webkit-scrollbar
  {
      width: 8px;
      background-color:transparent;
  }
  
  *::-webkit-scrollbar-thumb
  {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  }
}

// html[data-theme="light"] {
//   *::-webkit-scrollbar-thumb
//   {
//       background-color: #cacade;
//   }
// }

html[data-theme="dark"] {
  *::-webkit-scrollbar-thumb
  {
      background-color: white;
  }
}

.MuiAppBar-root {
  @media screen and (min-width: 768px) {
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.1), 0px 4px 5px 0px rgba(0,0,0,0.07), 0px 1px 10px 0px rgba(0,0,0,0.12) !important;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  }
}

.tox-tinymce {
  border-radius: 0 !important;
  height: calc(100vh - 64px) !important;
  z-index: 1;
  @media screen and (max-width: 767.9px) {
    margin-top: 8px;
    border-radius: 0;
    width: 100%;
    border: none;
    height: calc(100vh - 56px) !important;
  }
  @media screen and (max-width: 575.9px) {
    margin-top: 0;
    height: calc(100vh - 168px) !important;
  }
}

.browser-item {
  .browser-item-title {
    max-width: calc(100% - 5rem);
    @media screen and (max-width: 767.9px) {
      max-width: calc(100% - 6.5rem);
    }
  }
  @media screen and (max-width: 991.9px) {
    .item-action-bar {
      opacity: 1;
      right: 1rem;
    }
  }
}

.browser-item, .setting-box, .MuiDialogContent-root {
  @media screen and (max-width: 991.9px) {
    .MuiTypography-body1 {
      font-size: .75rem;
    }
  }
}

.corkboard-card {
  @media screen and (min-width: 992px) {
    .go-button-wrapper {
      transition: opacity 0.2s ease-in-out;
      opacity: 0;
    }

    &:hover {
      .go-button-wrapper {
        transition: opacity 0.2s ease-in-out;
        opacity: 1;
      }
    }
  }
}

header {
  z-index: 2;
}

.sticky-inner-wrapper {
  @media screen and (max-width: 767px) {
    transform: none !important;
  }
}